import React from 'react';
import ReactTable, { Column } from 'react-table';
import 'react-table/react-table.css';
import { Queue, TaxReturn } from './models';


const columns: Column<TaxReturn>[] = [
    {
        Header: 'Date Sent',
        accessor: 'DateReceived',
        Cell: row => { 
            var value = '';
            if(row.original.DateReceived) {
                var date = new Date(row.original.DateReceived);
                value = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            }
            return value;
        },
        maxWidth: 125,
        style: {
            textAlign: 'center'
        },
        resizable: false
    },
    {
        Header: 'Client App Identifier',
        accessor: 'ClientAppIdentifier',
        headerStyle: {
            textAlign: 'left'
        }
    },
    {
        Header: 'Form Type',
        accessor: 'Type',
        maxWidth: 125,
        style: {
            textAlign: 'center'
        },
        resizable: false
    },
    {
        Header: 'Tax Year',
        accessor: 'Year',
        maxWidth: 125,
        style: {
            textAlign: 'center'
        },
        resizable: false
    },
    {
        Header: 'Completed',
        accessor: 'ThirdPartyUploadDate',
        Cell: row => { 
            var value = '';
            if(row.original.ThirdPartyUploadDate) {
                var date = new Date(row.original.ThirdPartyUploadDate);
                value = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            }
            return value;
        },
        maxWidth: 135,
        style: {
            textAlign: 'center'
        },
        resizable: false
    },
    {
        Header: 'Notes',
        accessor: 'Notes',
        minWidth: 150,
        headerStyle: {
            textAlign: 'left'
        }
    }
];

const QueueTable: React.FC<{ queue: Queue, showName: boolean }> = ({ queue, showName }) => {
    return (
        <div className="row">
            <div className="col-12">
                {showName &&
                    <h3>{queue.Name}</h3>
                }
                <ReactTable data={queue.TaxReturns} columns={columns} minRows={5} />
            </div>
        </div>
    );
}

export default QueueTable;