import React from 'react';
import './App.scss';
import QueueList from './QueueList';
import { Queue } from './models';
import axios from 'axios';

interface Client {
  ClientName?: string;
  Queues: Queue[];
}

interface AppState {
  client: Client;
  loaded: boolean;
}

class App extends React.Component<{ match: any }, AppState> {
  state = { client: { ClientName: '', Queues: [] }, loaded: false };

  async componentDidMount() {
    var client = this.props.match.params.clientId;
    console.log(client);
    var resp = await axios.get<Client>(`_api/dashboard/${client}`);
    if(resp.status === 200)
      this.setState({ client: resp.data, loaded: true });
  }

  render() {
    if(!this.state.loaded) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="progress" style={{ height: "30px" }}>
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: '100%' }}>Loading...</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="App">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>{this.state.client.ClientName}<small> | Status Dashboard</small></h2>
            </div>
          </div>
          <hr />
          <QueueList Queues={this.state.client.Queues} />
        </div>
      </div>
    );
  }
}

export default App;
