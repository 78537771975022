import React from 'react';
import QueueTable from './QueueTable';
import { Queue } from './models';



const QueueList: React.FC<{ Queues: Queue[] }> = ({ Queues }) => {
    return (
        <div>
            {Queues.map(queue => <QueueTable key={queue.Name} queue={queue} showName={Queues.length > 1} />)}
        </div>
    );
}

export default QueueList;